import apiInstance from './configApis'

const updateDefectInference = async (elevation_id , url) => {
  try {
    const data = await apiInstance.post(`/ai/detect-inference`, {elevation_id, url});
    return data
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}
const getDefectNotInference = async (id) => {
  try {
    const data = await apiInstance.get(`/ai/get-urls-not-inference/${id}`)
    return data
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}



const maskingApis = {
  updateDefectInference,
  getDefectNotInference

}
export default maskingApis
